<template>
  <section>
    <div class="content-header">
      <h2>Helping students in distress</h2>
    </div>
    <div class="content-wrapper">
      <p>Faculty, instructors and educators are often the first to become aware that a student is in crisis or distress. As an initial point of contact, you can be instrumental in helping connect a student to needed support.</p>
      <p>You may be wondering:</p>
      <ul>
        <li>What constitutes an emergency situation? What other types of situations might require attention?</li>
        <li>What are the signs of distress to look out for? </li>
        <li>What should you say and do when a student is in distress?</li>
      </ul>
      <!-- <div class="carousel">
        <hooper
          ref="carousel_01"
          class="mb-4"
        >
          <slide>
            <div class="slide">
              <p>What constitutes an emergency situation? What other types of situations might require attention?</p>
            </div>
          </slide>

          <slide>
            <div class="slide">
              <p>What are the signs of distress to look out for? </p>
            </div>
          </slide>

          <slide>
            <div class="slide">
              <p>What should you say and do when a student is in distress?</p>
            </div>
          </slide>

          <hooper-navigation slot="hooper-addons">
            <img
              slot="hooper-prev"
              src="@/assets/img/_ui/svg/carousel-back.svg"
              alt="previous"
              srcset=""
            >
            <img
              slot="hooper-next"
              src="@/assets/img/_ui/svg/carousel-next.svg"
              alt="next"
              srcset=""
            >
          </hooper-navigation>

          <hooper-pagination slot="hooper-addons"></hooper-pagination>
        </hooper>
      </div> -->
      <p>Centennial College’s <a
          :href="`${publicPath}docs/community/Centennial - recognize-respond-connect-resource-guide.pdf`"
          download
        >Recognize, Respond, Connect Resource Guide</a> (used with permission from Rick Ezekiel) answers all of these questions. Download it and keep it handy.</p>
      <p class="lm">Learn more</p>
      <p>You can also check out Trent University’s one-page guide, <a
          :href="`${publicPath}docs/community/students_distress_lecterns (2) (1).pdf`"
          download
        >Responding to Students in Distress</a> (used with permission from Kate MacIsaac).</p>

    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
